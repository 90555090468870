
import { defineComponent, PropType } from "vue";
import { GridConfigActions } from "../../../interfaces/GridConfig.interface";

export default defineComponent({
  name: "ActionColumnTemplate",
  props: {
    actions: {
      type: Object as PropType<GridConfigActions>,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  emits: ["delete"],
});
