<template>
  <div v-if="hasFilter">
    <el-popover
      v-if="filter.tooltip"
      :content="filter.tooltip"
      width="100"
      trigger="hover"
      placement="top"
    >
      <template #reference>
        <component
          :is="filter.component"
          :filter="filter"
          :disabled="disabled"
          :value="value"
          @changed="onChanged"
        />
      </template>
    </el-popover>
    <div v-else>
      <component
        :is="filter.component"
        :filter="filter"
        :disabled="disabled"
        :value="value"
        @changed="onChanged"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TextFilter from "@/modules/resources/components/ResourceTable/filters/TextFilter.vue";
import SelectFilter from "@/modules/resources/components/ResourceTable/filters/SelectFilter.vue";
import MultiSelectFilter from "@/modules/resources/components/ResourceTable/filters/MultiSelectFilter.vue";
import DateRangeFilter from "@/modules/resources/components/ResourceTable/filters/DateRangeFilter.vue";

export default defineComponent({
  name: "ColumnHeaderFilter",
  components: { TextFilter, SelectFilter, MultiSelectFilter, DateRangeFilter },
  props: ["column", "filter", "disabled", "value"],
  emits: ["changed"],
  setup(props, { emit }) {
    const hasFilter = computed(() => props.filter != null);

    const onChanged = (value: string) => {
      emit("changed", props.column.field, value);
    };

    return {
      hasFilter,
      onChanged,
    };
  },
});
</script>

<style scoped></style>
