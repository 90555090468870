
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "MultiSelectFilter",
  props: ["value", "disabled", "filter"],
  emits: ["changed"],
  setup(props, { emit }) {
    const onChanged = (value: []) => {
      emit("changed", value);
    };

    const currentValue = computed(() => {
      let value = props.value;
      if (typeof value === "string") {
        value = [value];
      }
      return value;
    });

    return {
      onChanged,
      currentValue,
    };
  },
});
