import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { DateTime } from "luxon";


export default _defineComponent({
  props: ["value", "disabled", "filter"],
  emits: ["changed"],
  setup(__props, { emit }) {

const props = __props



const onCalendarChanged = (value: (Date | null)[]) => {
  if (value[0] !== null && value[1] !== null) {
    emit("changed", [
      DateTime.fromJSDate(value[0]).toFormat("yyyy-LL-dd"),
      DateTime.fromJSDate(value[1]).toFormat("yyyy-LL-dd"),
    ]);
  }
};

const onChange = (value) => {
  if (value == null) {
    emit("changed", value);
  }
};

const getValue = () => {
  return props.value != null
    ? [DateTime.fromISO(props.value[0]), DateTime.fromISO(props.value[1])]
    : null;
};

return (_ctx: any,_cache: any) => {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createBlock(_component_el_date_picker, {
    "model-value": getValue(),
    class: "datepicker-custom",
    disabled: __props.disabled,
    size: "small",
    type: "daterange",
    "range-separator": "-",
    "start-placeholder": "From",
    "end-placeholder": "To",
    onCalendarChange: onCalendarChanged,
    onChange: onChange
  }, null, 8, ["model-value", "disabled"]))
}
}

})