<template>
  <td>
    <component
      :is="column.component"
      :row="item"
      :column="column"
      :actions="actions"
    />
  </td>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ColumnOptions from "@/modules/resources/interfaces/ColumnOptions.interface";
import {
  BooleanColumn,
  KeyColumn,
  TextColumn,
} from "@/modules/resources/components/ResourceTable/columns/views";

export default defineComponent({
  name: "ResourceTableBodyCell",
  components: { TextColumn, BooleanColumn, KeyColumn },
  props: {
    item: {
      type: Object,
      required: true,
    },
    column: {
      type: Object as PropType<ColumnOptions>,
      required: true,
    },
    actions: {
      type: Object,
      required: false,
      default: null,
    },
  },
});
</script>

<style scoped></style>
