
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextFilter",
  props: ["value", "disabled"],
  emits: ["changed"],
  setup(props, { emit }) {
    const onChanged = (value: string) => {
      emit("changed", value);
    };

    return {
      onChanged,
    };
  },
});
