
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
} from "vue";
import GridConfig from "@/modules/resources/interfaces/GridConfig.interface";
import ApiService from "@/core/services/ApiService";
import ResourceTableHeaderColumn from "@/modules/resources/components/ResourceTable/ResourceTableHeaderColumn.vue";
import ResourceTableBodyCell from "@/modules/resources/components/ResourceTable/ResourceTableBodyCell.vue";
import {
  BooleanColumn,
  KeyColumn,
  TextColumn,
} from "@/modules/resources/components/ResourceTable/columns/views";
import ColumnHeaderFilter from "@/modules/resources/components/ResourceTable/columns/ColumnHeaderFilter.vue";
import ActionColumnTemplate from "@/modules/resources/components/ResourceTable/columns/ActionColumnTemplate.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import { useRoute, useRouter } from "vue-router";
import FilterOptions from "@/modules/resources/interfaces/FilterOptions.interface";

interface CurrentSort {
  field: string;
  order: string;
}

export default defineComponent({
  name: "ResourceTable",
  components: {
    RequestFailedError,
    ActionColumnTemplate,
    ColumnHeaderFilter,
    ResourceTableBodyCell,
    ResourceTableHeaderColumn,
    BooleanColumn,
    TextColumn,
    KeyColumn,
  },
  props: {
    resource: {
      type: String,
      required: true,
    },
    grid: {
      type: Object as PropType<GridConfig>,
      required: true,
    },
    initialSort: {
      type: Object as PropType<CurrentSort>,
      required: false,
      default: null,
    },
    initialFilters: {
      type: Object,
      required: false,
      default: null,
    },
    rowClass: {
      type: Function,
      required: false,
      default: null,
    },
  },
  expose: ["loadData"],
  setup(props, { slots, expose }) {
    const hasSlot = (name = "default"): boolean => {
      return !!slots[name];
    };

    /** Main State **/
    const initialState = {
      loading: false,
      error: undefined,
      items: undefined,
      pagination: undefined,
      currentPage: 1,
      perPage: 25,
      sorting: (props.initialSort || null) as CurrentSort | null,
      displayedExtraColumns: {},
    };

    const state = reactive({ ...initialState });

    const activeFilters = reactive(props.initialFilters || {});

    const resetState = () => {
      Object.keys(state).forEach(function (key) {
        state[key] = initialState[key];
      });
      Object.keys(activeFilters).forEach(function (key) {
        delete activeFilters[key];
      });
    };
    /** Main State End **/

    /** Filtering Start **/
    let changeTimer: number | undefined;

    const resetTimer = () => {
      if (changeTimer != null) {
        clearTimeout(changeTimer);
        changeTimer = undefined;
      }
    };

    const runTimer = () => {
      resetTimer();
      changeTimer = setTimeout(() => {
        router.replace({ query: { ...activeFilters } });
      }, 2000);
    };

    const onFilterChanged = (field: string, value: unknown) => {
      const filterValue = value === "" ? null : value;
      activeFilters[field] = filterValue;

      if (filterValue == null || filterValue == "") {
        activeFilters[field] = undefined;

        router.push({ query: activeFilters });
      } else {
        runTimer();
      }
    };

    const clearFilters = () => {
      router.replace({ query: {} });
    };

    const fillFiltersFromQuery = () => {
      Object.keys(route.query).forEach(function (key) {
        activeFilters[key] = route.query[key];
      });
    };

    const getFilter = (field: string): FilterOptions | null => {
      const filtered = props.grid.filters.filter(
        (filter) => filter.field === field
      );

      if (filtered.length === 0) {
        return null;
      }

      return filtered[0];
    };

    const sortChange = (field: string) => {
      state.currentPage = 1;

      if (state.sorting != null && state.sorting.field === field) {
        switch (state.sorting.order) {
          case "asc":
            state.sorting.order = "desc";
            break;
          case "desc":
            state.sorting = null;
            break;
        }
      } else {
        state.sorting = { field, order: "asc" };
      }

      loadData();
    };
    /** Filtering End **/

    const loadData = (isLoading = true) =>
      new Promise((resolve, reject) => {
        state.loading = isLoading;
        state.error = undefined;

        fillFiltersFromQuery();

        const filtersQuery = {};
        for (const filter in activeFilters) {
          if (activeFilters[filter] != null) {
            filtersQuery[`filters[${filter}]`] = activeFilters[filter];
          }
        }

        const params = {
          sort:
            state.sorting != null
              ? [state.sorting.field, state.sorting.order].join(",")
              : null,
          page: filtersQuery["filters[page]"],
          per_page: filtersQuery["filters[perPage]"],
          ...filtersQuery,
        };

        ApiService.query(`metronic/${props.resource}/grid/data`, {
          params: params,
        })
          .then(({ data: { data, pagination } }) => {
            state.items = data;
            state.pagination = pagination;

            state.currentPage = pagination.current_page;
            state.perPage = pagination.per_page;

            return resolve(true);
          })
          .catch((error) => {
            state.error = error;
          })
          .finally(() => {
            state.loading = false;
          })
          .catch(reject);
      });

    const pageChanged = (page) => {
      state.currentPage = page;

      onFilterChanged("page", state.currentPage);
    };

    const changePerPage = () => {
      onFilterChanged("perPage", state.perPage);
    };

    /** Action Column Start **/
    const hasActions = (): boolean => {
      return (
        hasSlot("actions") ||
        Object.keys(props.grid.actions).filter(
          (key) => props.grid.actions[key] === true
        ).length > 0
      );
    };

    const deleteItem = (row) => {
      state.loading = true;
      ApiService.post(`metronic/${props.resource}/delete/${row.id}`)
        .then(() => {
          ElMessage({
            type: "success",
            message: "Item deleted",
          });
          loadData();
        })
        .catch(() => {
          ElMessage({
            type: "error",
            message: "Oops, something went wrong",
          });
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const onDeleteRow = (row: unknown) => {
      ElMessageBox.confirm(
        "This will permanently delete the item. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          deleteItem(row);
        })
        .catch(() => {
          // do nothing
        });
    };
    /** Action Column End **/

    const toggleExtraFilter = (element: string, event) => {
      if (event.target.checked) {
        state.displayedExtraColumns[element] = true;
      } else {
        state.displayedExtraColumns[element] = null;
      }

      resetState();
      loadData();
    };

    /** Top Menu Listener Start **/
    const router = useRouter();
    const route = useRoute();
    const cancelListener = router.afterEach((to, from) => {
      if (to.path === from.path) {
        resetState();
        loadData(true);
      }
    });
    onUnmounted(cancelListener);
    /** Top Menu Listener End **/

    expose({
      loadData,
    });

    onMounted(() => {
      fillFiltersFromQuery();
      loadData();
    });

    const resolveRowClass = (item) => {
      if (props.rowClass == null) return {};

      return props.rowClass(item);
    };

    return {
      hasSlot,
      state,
      pageChanged,
      onFilterChanged,
      hasActions,
      onDeleteRow,
      sortChange,
      loadData,
      getFilter,
      activeFilters,
      clearFilters,
      toggleExtraFilter,
      route,
      changePerPage,
      resolveRowClass,
    };
  },
});
