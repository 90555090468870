
import ResourceTable from "../components/ResourceTable/ResourceTable.vue";
import ApiService from "../../../core/services/ApiService";
import LoadingIndicator from "../../../components/spinners/LoadingIndicator.vue";
import { defineComponent, onMounted, reactive, ref } from "vue";
import GridConfig from "@/modules/resources/interfaces/GridConfig.interface";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  grid?: GridConfig | undefined | null;
}

export default defineComponent({
  name: "ResourcePage",
  components: { RequestFailedError, LoadingIndicator, ResourceTable },
  props: {
    resource: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const cached = store.getters.getResourceCache(props.resource);

    const state = reactive<ComponentData>({
      loading: cached == null,
      error: undefined,
      grid: cached,
    });

    const resourceTable = ref<typeof ResourceTable | null>(null);

    const resetState = () => {
      state.loading = true;
      state.error = undefined;
      state.grid = undefined;
    };

    const loadGrid = () => {
      resetState();
      ApiService.get(`metronic/${props.resource}/grid`)
        .then(({ data }: { data: GridConfig }) => {
          state.grid = data;
          store.dispatch(Actions.SET_RESOURCE_DATA, {
            resource: props.resource,
            data,
          });
        })
        .catch((error) => {
          state.error = error;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    if (cached == null) onMounted(loadGrid);

    return { state, loadGrid, resourceTable };
  },
});
