<template>
  <el-input
    :model-value="value"
    :disabled="disabled"
    placeholder="Search..."
    clearable
    size="small"
    @input="onChanged"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextFilter",
  props: ["value", "disabled"],
  emits: ["changed"],
  setup(props, { emit }) {
    const onChanged = (value: string) => {
      emit("changed", value);
    };

    return {
      onChanged,
    };
  },
});
</script>

<style scoped></style>
