
import { computed, defineComponent } from "vue";
import TextFilter from "@/modules/resources/components/ResourceTable/filters/TextFilter.vue";
import SelectFilter from "@/modules/resources/components/ResourceTable/filters/SelectFilter.vue";
import MultiSelectFilter from "@/modules/resources/components/ResourceTable/filters/MultiSelectFilter.vue";
import DateRangeFilter from "@/modules/resources/components/ResourceTable/filters/DateRangeFilter.vue";

export default defineComponent({
  name: "ColumnHeaderFilter",
  components: { TextFilter, SelectFilter, MultiSelectFilter, DateRangeFilter },
  props: ["column", "filter", "disabled", "value"],
  emits: ["changed"],
  setup(props, { emit }) {
    const hasFilter = computed(() => props.filter != null);

    const onChanged = (value: string) => {
      emit("changed", props.column.field, value);
    };

    return {
      hasFilter,
      onChanged,
    };
  },
});
