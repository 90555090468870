
import { defineComponent } from "vue";
import TextColumn from "@/modules/resources/components/ResourceTable/columns/views/TextColumn.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "KeyColumn",
  extends: TextColumn,
  setup() {
    const route = useRoute();

    return { route };
  },
});
