<template>
  <request-failed-error
    v-if="state.error"
    :exception="state.error"
    @refresh="loadData"
  />
  <div v-if="state.items" class="row mb-3">
    <em>
      {{ state.items.length }}
      {{ state.items.length > 1 ? "items" : "item" }} displayed
    </em>
  </div>
  <div class="row">
    <div class="col-4 my-auto">
      <template v-if="state.pagination != null">
        <el-pagination
          layout="prev, pager, next"
          :total="state.pagination.total"
          :page-size="state.pagination.per_page"
          :disabled="state.loading"
          :current-page="state.currentPage"
          @current-change="pageChanged"
        ></el-pagination>
      </template>
    </div>
    <div class="col-2 offset-6">
      <label for="perPage">Per page</label>
      <select
        id="perPage"
        v-model="state.perPage"
        class="form-select d-inline form-select-sm mx-4"
        name="perPage"
        style="width: 80px"
        @change="() => changePerPage()"
      >
        <option value="10">10</option>
        <option value="25" selected>25</option>
        <option value="50">50</option>
      </select>
    </div>
  </div>
  <div
    v-if="state.error == null"
    class="table-responsive"
    :class="[state.loading && 'overlay overlay-block']"
  >
    <table
      class="table table-row-bordered table-row-gray-100 gy-3 table-hover"
      role="grid"
    >
      <thead>
        <tr>
          <resource-table-header-column
            v-for="column in grid.columns"
            :key="'table-column-' + column.field"
            :column="column"
            :sorting="state.sorting"
            :filters="activeFilters"
            :displayed-extra-columns="state.displayedExtraColumns"
            @sort-changed="sortChange"
          >
            <template #filter>
              <div
                v-if="
                  activeFilters[column.field] ||
                  column.appearance !== 'extra' ||
                  state.displayedExtraColumns[column.field] != null
                "
              >
                <column-header-filter
                  :column="column"
                  :filter="getFilter(column.field)"
                  :disabled="state.loading"
                  :value="activeFilters[column.field] || null"
                  @changed="onFilterChanged"
                />
              </div>
            </template>
          </resource-table-header-column>
          <th v-if="hasActions" class="min-w-200px">
            <el-popover
              placement="left"
              title="Extra filters"
              :width="400"
              trigger="click"
              content="this is content, this is content, this is content"
            >
              <template #reference>
                <button class="btn btn-sm btn-secondary mx-2">
                  <i class="fas fa-filter"></i>
                </button>
              </template>
              <template #default>
                <div class="popover-body">
                  <div v-for="column in grid.columns" :key="column.id">
                    <div v-if="column.appearance === 'extra'" class="row mb-4">
                      <label>
                        {{ column.name }}
                        <input
                          id="flexCheckDefault"
                          class="form-check-input"
                          type="checkbox"
                          @click="
                            (event) => toggleExtraFilter(column.field, event)
                          "
                        />
                      </label>
                      <column-header-filter
                        :column="column"
                        :filter="getFilter(column.field)"
                        :disabled="state.loading"
                        :value="activeFilters[column.field] || null"
                        @changed="onFilterChanged"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </el-popover>
            <button
              class="btn btn-sm btn-secondary mx-2"
              @click="() => clearFilters()"
            >
              Clear filters
            </button>
          </th>
        </tr>
      </thead>
      <tbody class="vertically-aligned">
        <tr
          v-for="item in state.items"
          :key="item.id"
          :class="resolveRowClass(item)"
        >
          <td
            v-for="column in grid.columns"
            :key="'row-' + column.field + '-' + item.id"
            class="px-1"
            :class="{
              display_none:
                !activeFilters[column.field] &&
                column.appearance === 'extra' &&
                state.displayedExtraColumns[column.field] == null,
            }"
          >
            <slot
              :name="`column-${column.field.replace('.', '-')}`"
              :row="item"
            />
            <component
              :is="column.component"
              v-if="!hasSlot(`column-${column.field.replace('.', '-')}`)"
              :row="item"
              :column="column"
              :actions="grid.actions"
            />
          </td>
          <td v-if="hasActions">
            <slot name="actions" :row="item" />
            <action-column-template
              v-if="!hasSlot('actions')"
              :actions="grid.actions"
              :row="item"
              @delete="onDeleteRow"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="state.loading"
      class="overlay-layer rounded bg-dark bg-opacity-5"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <template v-if="state.pagination != null">
    <el-pagination
      layout="prev, pager, next"
      :total="state.pagination.total"
      :page-size="state.pagination.per_page"
      :disabled="state.loading"
      :current-page="state.currentPage"
      @current-change="pageChanged"
    ></el-pagination>
  </template>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
} from "vue";
import GridConfig from "@/modules/resources/interfaces/GridConfig.interface";
import ApiService from "@/core/services/ApiService";
import ResourceTableHeaderColumn from "@/modules/resources/components/ResourceTable/ResourceTableHeaderColumn.vue";
import ResourceTableBodyCell from "@/modules/resources/components/ResourceTable/ResourceTableBodyCell.vue";
import {
  BooleanColumn,
  KeyColumn,
  TextColumn,
} from "@/modules/resources/components/ResourceTable/columns/views";
import ColumnHeaderFilter from "@/modules/resources/components/ResourceTable/columns/ColumnHeaderFilter.vue";
import ActionColumnTemplate from "@/modules/resources/components/ResourceTable/columns/ActionColumnTemplate.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import { useRoute, useRouter } from "vue-router";
import FilterOptions from "@/modules/resources/interfaces/FilterOptions.interface";

interface CurrentSort {
  field: string;
  order: string;
}

export default defineComponent({
  name: "ResourceTable",
  components: {
    RequestFailedError,
    ActionColumnTemplate,
    ColumnHeaderFilter,
    ResourceTableBodyCell,
    ResourceTableHeaderColumn,
    BooleanColumn,
    TextColumn,
    KeyColumn,
  },
  props: {
    resource: {
      type: String,
      required: true,
    },
    grid: {
      type: Object as PropType<GridConfig>,
      required: true,
    },
    initialSort: {
      type: Object as PropType<CurrentSort>,
      required: false,
      default: null,
    },
    initialFilters: {
      type: Object,
      required: false,
      default: null,
    },
    rowClass: {
      type: Function,
      required: false,
      default: null,
    },
  },
  expose: ["loadData"],
  setup(props, { slots, expose }) {
    const hasSlot = (name = "default"): boolean => {
      return !!slots[name];
    };

    /** Main State **/
    const initialState = {
      loading: false,
      error: undefined,
      items: undefined,
      pagination: undefined,
      currentPage: 1,
      perPage: 25,
      sorting: (props.initialSort || null) as CurrentSort | null,
      displayedExtraColumns: {},
    };

    const state = reactive({ ...initialState });

    const activeFilters = reactive(props.initialFilters || {});

    const resetState = () => {
      Object.keys(state).forEach(function (key) {
        state[key] = initialState[key];
      });
      Object.keys(activeFilters).forEach(function (key) {
        delete activeFilters[key];
      });
    };
    /** Main State End **/

    /** Filtering Start **/
    let changeTimer: number | undefined;

    const resetTimer = () => {
      if (changeTimer != null) {
        clearTimeout(changeTimer);
        changeTimer = undefined;
      }
    };

    const runTimer = () => {
      resetTimer();
      changeTimer = setTimeout(() => {
        router.replace({ query: { ...activeFilters } });
      }, 2000);
    };

    const onFilterChanged = (field: string, value: unknown) => {
      const filterValue = value === "" ? null : value;
      activeFilters[field] = filterValue;

      if (filterValue == null || filterValue == "") {
        activeFilters[field] = undefined;

        router.push({ query: activeFilters });
      } else {
        runTimer();
      }
    };

    const clearFilters = () => {
      router.replace({ query: {} });
    };

    const fillFiltersFromQuery = () => {
      Object.keys(route.query).forEach(function (key) {
        activeFilters[key] = route.query[key];
      });
    };

    const getFilter = (field: string): FilterOptions | null => {
      const filtered = props.grid.filters.filter(
        (filter) => filter.field === field
      );

      if (filtered.length === 0) {
        return null;
      }

      return filtered[0];
    };

    const sortChange = (field: string) => {
      state.currentPage = 1;

      if (state.sorting != null && state.sorting.field === field) {
        switch (state.sorting.order) {
          case "asc":
            state.sorting.order = "desc";
            break;
          case "desc":
            state.sorting = null;
            break;
        }
      } else {
        state.sorting = { field, order: "asc" };
      }

      loadData();
    };
    /** Filtering End **/

    const loadData = (isLoading = true) =>
      new Promise((resolve, reject) => {
        state.loading = isLoading;
        state.error = undefined;

        fillFiltersFromQuery();

        const filtersQuery = {};
        for (const filter in activeFilters) {
          if (activeFilters[filter] != null) {
            filtersQuery[`filters[${filter}]`] = activeFilters[filter];
          }
        }

        const params = {
          sort:
            state.sorting != null
              ? [state.sorting.field, state.sorting.order].join(",")
              : null,
          page: filtersQuery["filters[page]"],
          per_page: filtersQuery["filters[perPage]"],
          ...filtersQuery,
        };

        ApiService.query(`metronic/${props.resource}/grid/data`, {
          params: params,
        })
          .then(({ data: { data, pagination } }) => {
            state.items = data;
            state.pagination = pagination;

            state.currentPage = pagination.current_page;
            state.perPage = pagination.per_page;

            return resolve(true);
          })
          .catch((error) => {
            state.error = error;
          })
          .finally(() => {
            state.loading = false;
          })
          .catch(reject);
      });

    const pageChanged = (page) => {
      state.currentPage = page;

      onFilterChanged("page", state.currentPage);
    };

    const changePerPage = () => {
      onFilterChanged("perPage", state.perPage);
    };

    /** Action Column Start **/
    const hasActions = (): boolean => {
      return (
        hasSlot("actions") ||
        Object.keys(props.grid.actions).filter(
          (key) => props.grid.actions[key] === true
        ).length > 0
      );
    };

    const deleteItem = (row) => {
      state.loading = true;
      ApiService.post(`metronic/${props.resource}/delete/${row.id}`)
        .then(() => {
          ElMessage({
            type: "success",
            message: "Item deleted",
          });
          loadData();
        })
        .catch(() => {
          ElMessage({
            type: "error",
            message: "Oops, something went wrong",
          });
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const onDeleteRow = (row: unknown) => {
      ElMessageBox.confirm(
        "This will permanently delete the item. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          deleteItem(row);
        })
        .catch(() => {
          // do nothing
        });
    };
    /** Action Column End **/

    const toggleExtraFilter = (element: string, event) => {
      if (event.target.checked) {
        state.displayedExtraColumns[element] = true;
      } else {
        state.displayedExtraColumns[element] = null;
      }

      resetState();
      loadData();
    };

    /** Top Menu Listener Start **/
    const router = useRouter();
    const route = useRoute();
    const cancelListener = router.afterEach((to, from) => {
      if (to.path === from.path) {
        resetState();
        loadData(true);
      }
    });
    onUnmounted(cancelListener);
    /** Top Menu Listener End **/

    expose({
      loadData,
    });

    onMounted(() => {
      fillFiltersFromQuery();
      loadData();
    });

    const resolveRowClass = (item) => {
      if (props.rowClass == null) return {};

      return props.rowClass(item);
    };

    return {
      hasSlot,
      state,
      pageChanged,
      onFilterChanged,
      hasActions,
      onDeleteRow,
      sortChange,
      loadData,
      getFilter,
      activeFilters,
      clearFilters,
      toggleExtraFilter,
      route,
      changePerPage,
      resolveRowClass,
    };
  },
});
</script>

<style>
.multiselect-input {
  min-height: 35px;
  border-radius: 10px;
  border-color: transparent;
  background-color: #f5f8fa;
}

.is-multiple .multiselect-search input,
.is-single .multiselect-search input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background-color: #f5f8fa;
  color: #5e6278;
}

.multiselect-clear {
  background-color: #f5f8fa;
}

.vertically-aligned td {
  vertical-align: middle;
  min-height: 58px;
}

.display_none {
  display: none;
}
</style>
