<template>
  <el-select-v2
    :model-value="value"
    filterable
    remote
    clearable
    :fit-input-width="true"
    size="small"
    placeholder="Select"
    :disabled="disabled"
    :options="options"
    :remote-method="remoteMethod"
    @change="onChanged"
  >
  </el-select-v2>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

interface ListItem {
  value: string;
  label: string;
}

export default defineComponent({
  name: "SelectFilter",
  props: ["value", "disabled", "filter"],
  emits: ["changed"],
  setup(props, { emit }) {
    const onChanged = (value: string) => {
      if (value === "") {
        options.value = defaultOptions;
      }
      emit("changed", value);
    };

    // For default values:
    const defaultOptions = Object.keys(props.filter.options).map(
      (item): ListItem => {
        return { value: item, label: props.filter.options[item] };
      }
    );

    //For filtered values:
    const options = ref<ListItem[]>(defaultOptions);

    const remoteMethod = (query: string) => {
      if (query !== "") {
        options.value = defaultOptions.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });
      } else {
        options.value = defaultOptions;
      }
    };

    return {
      onChanged,
      remoteMethod,
      options,
    };
  },
});
</script>

<style scoped></style>
