<template>
  <el-select
    :model-value="currentValue"
    filterable
    multiple
    clearable
    :fit-input-width="true"
    size="small"
    placeholder="Select"
    class="w-full text-nowrap"
    :disabled="disabled"
    @change="onChanged"
  >
    <el-option
      v-for="(label, value) in filter.options"
      :key="value"
      :label="label.title || label.name || label"
      :value="value"
    >
      <span v-if="label.color">
        <span
          class="badge"
          :style="'background-color:' + (label.color || '#000')"
        >
          {{ label.title || label.name || label }}
        </span>
      </span>
    </el-option>
  </el-select>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "MultiSelectFilter",
  props: ["value", "disabled", "filter"],
  emits: ["changed"],
  setup(props, { emit }) {
    const onChanged = (value: []) => {
      emit("changed", value);
    };

    const currentValue = computed(() => {
      let value = props.value;
      if (typeof value === "string") {
        value = [value];
      }
      return value;
    });

    return {
      onChanged,
      currentValue,
    };
  },
});
</script>

<style scoped>
.selected {
  background-color: #bce7ff !important;
}
</style>
