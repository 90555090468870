
import { defineComponent, PropType } from "vue";
import ColumnOptions from "@/modules/resources/interfaces/ColumnOptions.interface";

export default defineComponent({
  name: "ResourceTableHeaderColumn",
  props: {
    column: {
      type: Object as PropType<ColumnOptions>,
      required: true,
    },
    sorting: {
      type: Object,
      required: false,
      default: null,
    },
    filters: {
      type: Array,
      required: false,
      default: null,
    },
    displayedExtraColumns: {
      type: Array,
      required: false,
      default: null,
    },
  },
  emits: ["sort-changed"],
  setup(props, { emit }) {
    const isSortable = props.column.sortable === true;
    const columnWidth = props.column.width ? `${props.column.width}px` : null;

    const onSort = () => {
      if (props.column.sortable) {
        emit("sort-changed", props.column.field);
      }
    };

    return {
      isSortable,
      columnWidth,
      onSort,
    };
  },
});
