<template>
  <router-link
    v-if="actions.view"
    :to="{ name: route.name + '-view', params: { id: resolvedValue } }"
  >
    {{ resolvedValue }}
  </router-link>
  <span v-else>{{ resolvedValue }}</span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TextColumn from "@/modules/resources/components/ResourceTable/columns/views/TextColumn.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "KeyColumn",
  extends: TextColumn,
  setup() {
    const route = useRoute();

    return { route };
  },
});
</script>

<style scoped></style>
