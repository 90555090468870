
import { defineComponent, PropType } from "vue";
import ColumnOptions from "@/modules/resources/interfaces/ColumnOptions.interface";
import get from "lodash/get";
import { GridConfigActions } from "@/modules/resources/interfaces/GridConfig.interface";

export default defineComponent({
  name: "TextColumn",
  props: {
    rowIndex: {
      type: Number,
      required: false,
      default: null,
    },
    row: {
      type: Object,
      required: true,
    },
    column: {
      type: Object as PropType<ColumnOptions>,
      required: true,
    },
    actions: {
      type: Object as PropType<GridConfigActions>,
      required: true,
    },
  },
  computed: {
    resolvedValue: function (): string {
      return get(this.row, this.column.field, "");
    },
  },
});
