<template>
  <div class="text-center min-w-150px">
    <router-link
      v-if="actions.view"
      :to="{ name: $route.name + '-view', params: { id: row.id } }"
      class="btn btn-light btn-icon m-1"
    >
      <span class="fas fa-eye"></span>
    </router-link>
    <router-link
      v-if="actions.update"
      :to="{ name: $route.name + '-update', params: { id: row.id } }"
      class="btn btn-light btn-icon m-1"
    >
      <span class="fas fa-edit"></span>
    </router-link>
    <button
      v-if="actions.delete"
      class="btn btn-light btn-icon m-1"
      @click="$emit('delete', row)"
    >
      <span class="fas fa-trash"></span>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GridConfigActions } from "../../../interfaces/GridConfig.interface";

export default defineComponent({
  name: "ActionColumnTemplate",
  props: {
    actions: {
      type: Object as PropType<GridConfigActions>,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  emits: ["delete"],
});
</script>

<style scoped></style>
