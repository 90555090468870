
import { defineComponent, PropType } from "vue";
import get from "lodash/get";
import { GridConfigActions } from "@/modules/resources/interfaces/GridConfig.interface";
import BooleanColumnOptionsInterface from "@/modules/resources/interfaces/BooleanColumnOptions.interface";

export default defineComponent({
  name: "BooleanColumn",
  props: {
    rowIndex: {
      default: null,
      type: Number,
      required: false,
    },
    row: {
      type: Object,
      required: true,
    },
    column: {
      type: Object as PropType<BooleanColumnOptionsInterface>,
      required: true,
    },
    actions: {
      type: Object as PropType<GridConfigActions>,
      required: true,
    },
  },
  computed: {
    resolvedValue: function (): string {
      return get(this.row, this.column.field, false);
    },
  },
});
