<template>
  <th
    v-if="
      filters[column.field] ||
      column.appearance !== 'extra' ||
      displayedExtraColumns[column.field] != null
    "
    :style="{
      width: columnWidth,
    }"
    :class="{
      'min-w-100px': columnWidth === null,
    }"
    class="align-top px-1"
  >
    <div
      class="text-gray-700 fs-7 text-truncate"
      :class="{
        'cursor-pointer': isSortable === true,
        'fw-bold': sorting,
      }"
      @click="onSort"
    >
      <span :title="column.name">{{ column.name }}</span>
      <i
        v-if="isSortable"
        class="fas ms-1"
        :class="{
          'fa-sort': sorting == null || sorting.field !== column.field,
          'fa-sort-up':
            sorting != null &&
            sorting.field === column.field &&
            sorting.order === 'desc',
          'fa-sort-down':
            sorting != null &&
            sorting.field === column.field &&
            sorting.order === 'asc',
        }"
      />
    </div>
    <slot name="filter" />
  </th>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ColumnOptions from "@/modules/resources/interfaces/ColumnOptions.interface";

export default defineComponent({
  name: "ResourceTableHeaderColumn",
  props: {
    column: {
      type: Object as PropType<ColumnOptions>,
      required: true,
    },
    sorting: {
      type: Object,
      required: false,
      default: null,
    },
    filters: {
      type: Array,
      required: false,
      default: null,
    },
    displayedExtraColumns: {
      type: Array,
      required: false,
      default: null,
    },
  },
  emits: ["sort-changed"],
  setup(props, { emit }) {
    const isSortable = props.column.sortable === true;
    const columnWidth = props.column.width ? `${props.column.width}px` : null;

    const onSort = () => {
      if (props.column.sortable) {
        emit("sort-changed", props.column.field);
      }
    };

    return {
      isSortable,
      columnWidth,
      onSort,
    };
  },
});
</script>

<style scoped></style>
