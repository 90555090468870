<template>
  <div class="row">
    <div class="col">
      <div class="card shadow-sm">
        <div class="card-header">
          <h3 class="card-title">{{ title }}</h3>
          <div v-if="state.grid != null" class="card-toolbar">
            <router-link
              v-if="state.grid.actions.create"
              :to="{ name: $route.name + '-create' }"
              type="button"
              class="btn btn-sm btn-light"
            >
              + Create
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <request-failed-error
            v-if="state.error"
            :exception="state.error"
            @refresh="loadGrid"
          />
          <slot v-if="state.grid != null" :grid="state.grid">
            <resource-table
              ref="resourceTable"
              :resource="resource"
              :grid="state.grid"
            />
          </slot>
          <div v-if="state.loading" class="p-4 text-center">
            <loading-indicator />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ResourceTable from "../components/ResourceTable/ResourceTable.vue";
import ApiService from "../../../core/services/ApiService";
import LoadingIndicator from "../../../components/spinners/LoadingIndicator.vue";
import { defineComponent, onMounted, reactive, ref } from "vue";
import GridConfig from "@/modules/resources/interfaces/GridConfig.interface";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  grid?: GridConfig | undefined | null;
}

export default defineComponent({
  name: "ResourcePage",
  components: { RequestFailedError, LoadingIndicator, ResourceTable },
  props: {
    resource: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const cached = store.getters.getResourceCache(props.resource);

    const state = reactive<ComponentData>({
      loading: cached == null,
      error: undefined,
      grid: cached,
    });

    const resourceTable = ref<typeof ResourceTable | null>(null);

    const resetState = () => {
      state.loading = true;
      state.error = undefined;
      state.grid = undefined;
    };

    const loadGrid = () => {
      resetState();
      ApiService.get(`metronic/${props.resource}/grid`)
        .then(({ data }: { data: GridConfig }) => {
          state.grid = data;
          store.dispatch(Actions.SET_RESOURCE_DATA, {
            resource: props.resource,
            data,
          });
        })
        .catch((error) => {
          state.error = error;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    if (cached == null) onMounted(loadGrid);

    return { state, loadGrid, resourceTable };
  },
});
</script>

<style scoped></style>
